/* Верхняя часть профиля */
.user-profile__top {
    display: flex;
    height: 100px;
    gap: var(--gap-small);
}

/* Блок с именем и тегом */
.user-profile__top-name {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: var(--gap-small);
}

/* Высота текста и обрезка */
.user-profile__top-name h2 {
    max-height: 58px;
    overflow: hidden;
    text-overflow: ellipsis;
}

.user-profile__top-tag {
    width: fit-content;
    align-items: center;
    gap: var(--button-gap);
    cursor: pointer;
}

/* Фотография */
.user-profile__top-photo {
    height: 100px;
}

/* Круглая форма */
.user-profile__top-photo img {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    
    background-color: var(--bg-primary);
    object-fit: cover;
}

/* Кнопки в профиле */
.user-profile__buttons {
    flex-wrap: wrap;
}

/* Строка с информацией */
.user-profile__row {
    width: 100%;
    display: flex;
    align-items: center;
}

.user-profile__row > p {
    width: 40%;
}

.user-profile__row > button {
    width: 60%;
}