/* Контент в 100% */
@media screen and (max-width: 900px) {

}

/* Мобильное сжатие */
@media screen and (max-width: 400px) {
    /* Делаем блок в столбик */
    .dice__luck {
        flex-direction: column;
    }

    .dice__luck-wrapper {
        flex: none;
    }
}