/* Фотография */
.dossier__photo {
    width: 140px;
}

.dossier__photo img {
    width: 140px;
    height: 140px;
    object-fit: cover;
}

.dossier__photo_border {
    border: 1px solid white;
    border-radius: var(--border-radius);
    background-color: var(--bg-primary);
}

/* Информация о существе */
.dossier__info {
    gap: 4px;
}

.dossier__info .text-red {
    /* font-weight: 800; */
}

/* Имя */
.dossier__info h3 {
    margin-bottom: var(--gap-small);
}


/* Блок с досье */
.dossier__dossier {
    border: 1px solid var(--border-input);
    border-radius: var(--border-radius);
    white-space: pre-wrap;
    padding: var(--gap-small);
}