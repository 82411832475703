/* Контент в 100% */
@media screen and (max-width: 900px) {
    /* Верхняя часть профиля */
    .country-page__top {
        flex-direction: column;
        height: auto;
        align-items: center;
    }

    /* Блок с именем и тегом */
    .country-page__top-name {
        align-items: center;
    }

    /* Высота текста и обрезка */
    .country-page__top-name h2 {
        max-height: none;
    }

    /* Фотография */
    .country-page__top-photo {
        height: 100px;
    }

    /* Круглая форма */
    .country-page__top-photo img {
        width: 100px;
        height: 100px;
        border-radius: 50%;
        
        background-color: var(--bg-primary);
        object-fit: cover;
    }
}