/* Блок с инпут и лейбл */
.settings__radio-input {
    gap: 0;
    align-items: center;
    cursor: pointer;
}

.settings__radio-input label {
    width: calc(100% - 16px); /* Ограничиваем ширину что бы не сжимал инпут */
    padding-left: 12px;
    cursor: inherit;
}