/* Скрываем мобильные кнопки */
#nav-menu-bg,
#nav-logo-phone,
#nav-phone-country,
#nav-phone-user,
#nav-phone-login,
#nav-menu-open,
#nav-menu-close {
    display: none;
}

/* Контент в 100% */
@media screen and (max-width: 900px) {
    /* Обвертка навигации, по умолчанию скрыта на телефоне */
    .nav-wrapper {
        display: none;
        width: calc(201px + var(--gap-small) + var(--gap-small)); /* Ширина 200px + отступы */
        padding: 0px 0px var(--gap-small) 0px;
        
        background-color: var(--bg-secondary);
        border-left: 1px solid var(--border-primary);

        position: fixed;
        top: 0px;
        right: 0;
    }

    /* Добавляем scrollbar отступ снизу */
    .nav-wrapper::-webkit-scrollbar-track {
        margin: var(--gap-small) 0;
    }

    /* Навигация */
    nav {
        width: calc(100% - var(--gap-small) - var(--gap-small)); /* Расстояние между контентом и прокруткой - отступы */
        padding: 0;
        border-radius: none;
        border: none;
        gap: 0px;
        margin: 0 auto;
    }

    #nav-logo {
        display: none;
    }

    #nav-logo-phone {
        display: flex;
        width: var(--button-height-big);
        height: var(--button-height-big);

        margin-left: 8px;
    }

    #nav-logo-phone img {
        width: var(--button-height-big);
        height: var(--button-height-big);
    }


    /* Кнопку открытия навигации видно только на маленьких экранах */
    #nav-menu-open {
        display: flex;
        width: var(--button-height-big);
        height: var(--button-height-big);

        position: absolute;
        top: 0px;
        right: 8px;
    }

    #nav-menu-open img {
        width: var(--button-img-big);
        height: var(--button-img-big);
    }

    /* Кнопка закрытия навигации */
    #nav-menu-close {
        display: flex;
        width: var(--button-height-big);
        height: var(--button-height-big);
        margin: 0px 0 12px auto;
    }

    #nav-menu-close img {
        width: var(--button-img-big);
        height: var(--button-img-big);
    }

    /* Скрытие полосы у навигации */
    #nav-menu-close::after {
        content: "";
        width: 2px;
        height: 49px;

        position: fixed;
        top: 0;
        right: 224px;

        background-color: var(--bg-secondary);
    }

    /* Подложка навигации */
    #nav-menu-bg {
        width: 100%;
        height: calc(100% - 50px);

        position: fixed;
        top: 50px;
        left: 0;
        background-color: var(--bg-transparent);

        cursor: pointer;

        z-index: var(--z-nav-bg);
    }

    /* Показывать ли меню */
    .show-nav-menu {
        display: block !important;
    }

    #nav-phone-login {
        width: fit-content;
        height: var(--button-height-big);

        display: flex;
        position: absolute;
        top: 0px;
        right: calc(var(--button-height-big) + 8px + 8px); /* Двойной отступ по 8px */
    }

    /* Кнопка юзера в моб. версии */
    #nav-phone-user {
        width: var(--button-height-big);
        height: var(--button-height-big);

        display: flex;
        position: absolute;
        top: 0px;
        right: calc(var(--button-height-big) + 8px + 8px); /* Двойной отступ по 8px */
    }

    /* Кнопка страны в моб. версии */
    #nav-phone-country {
        width: var(--button-height-big);
        height: var(--button-height-big);

        display: flex;
        position: absolute;
        top: 0px;
        right: calc(var(--button-height-big) + 8px + var(--button-height-big) + 8px + 8px); /* Двойной отступ по 8px */
    }
}