/* Контент в 100% */
@media screen and (max-width: 900px) {
    /* Страница входа */
    #article-login {
        /* Одинаковый отступ со всех сторон страницы */
        padding: var(--gap-small);
    }

    #article-login .logo-wrapper {
        width: min(100px, 100%);
        height: 100px;
    }
}