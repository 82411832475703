/* Страница входа */
#article-login {
    /* Сверху отступ больше*/
    padding: var(--gap-medium);
}

/* Логотип над формой */
#article-login .logo-wrapper {
    width: min(140px, 100%);
    height: 140px;
    display: flex;
    justify-content: center;
    align-items: center;

    margin: 0 auto ;
}

#article-login .logo-wrapper img {
    width: 100%;
    cursor: pointer;
}

/* Секция входа */
#article-login section {
    width: min(384px, 100%);
    display: flex;
    flex-direction: column;
    gap: var(--gap-small);

    margin: 0 auto;
}

#article-login section h1 {
    width: 100%;
    text-align: center;
}

#article-login section > button {
    width: 100%;
}

/* Заголовок */
#article-login .page-title {
    width: min(384px, 100%);
}

.login__token-row {
    align-items: flex-end;
}

.login__token-row .custom-input {
    flex: 1;
}

.login__token-row button {
    height: var(--button-height);
}