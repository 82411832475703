/* Обвертка навигации */
.nav-wrapper {
    width: 100%;
    height: 100%;
    padding: var(--gap-medium) 0; /* Внешний отступ блоков - 20px */

    overflow: overlay; /* Прокрутка поверх */
    -ms-overflow-style: -ms-autohiding-scrollbar;

    z-index: var(--z-nav);
}

/* Добавляем scrollbar отступ снизу */
.nav-wrapper::-webkit-scrollbar-track {
    margin: var(--gap-medium) 0;
}

/* Навигация */
nav {
    width: 200px;
    display: flex;
    flex-direction: column;
    gap: var(--gap-small);
}


/* Навигация с задним фоном на пк */
@media screen and (min-width: 900px) {
    body[bg] nav {
        width: calc(100% - var(--gap-small));
        padding: 0 var(--gap-small) var(--gap-small) var(--gap-small);

        background-color: var(--bg-secondary);
        border-radius: var(--border-radius);
        border: 1px solid var(--border-primary);
    }
}

/* Кнопки */
nav ul {
    display: flex;
    flex-direction: column;
}

/* Контейнер кнопки */
nav ul li {
    user-select: none;
}

/* Кнопка профиля и страны */
nav ul li button {
    height: var(--button-height-nav);
}

/* Кнопка навигации */
nav ul li a {
    width: 100%;
    height: var(--button-height-nav);
    padding: var(--button-padding);
    position: relative;

    display: flex;
    align-items: center;
    gap: var(--button-gap);

    outline: none;
    border-radius: var(--border-radius);
    background-color: var(--button-bg-tp);

    cursor: pointer;
}

nav ul li a img {
    width: var(--button-img-nav);
    height: var(--button-img-nav);
}

/* Активная вкладка навигации (Стрелочка сбоку) */
nav ul li a.active::after {
    content: "";
    width: 20px;
    height: 20px;
    background-image: url("../../assets/svg/Arrow-right.svg");
    background-position: center;
    background-repeat: no-repeat;

    position: absolute;
    top: 50%;
    right: var(--button-gap);
    transform: translateY(-50%);
}

/* Наведение на пк */
@media (hover: hover) {
    nav ul li a:hover,
    nav ul li a:focus-visible {
        background-color: var(--button-hover-tp);
    }
}

/* Клик на телефоне */
@media (hover: none) {
    nav ul li a:active {
        background-color: var(--button-hover-tp);
    }
}


/* Разделитель в навигации с отступами */
nav ul hr {
    margin: var(--gap-small) auto; /* Центр */
}


/* Логотип */
#nav-logo {
    width: 100%;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
}

#nav-logo a {
    width: 100px;
    height: 100px;
}

#nav-logo img {
    width: 100px;
    height: 100px;
}