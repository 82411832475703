/* Кнопка "Сохранить" по центру */
.news-add > button {
    margin: 0 auto;
}

.news-add__input-row {
    width: 100%;
    align-items: flex-end;
}

/* Даем кнопке "Добавить" ширину */
.news-add__input-row .custom-input {
    flex: 1;
}


/* Текст превью */
.news-add__preview-text {
    text-align: center;
}

/* Картинка с превью */
.news-add__preview {
    width: fit-content;
    margin: 0 auto;
}

.news-add__preview img {
    width: 100%;
    height: 100px;
    object-fit: contain;
    border-radius: var(--border-radius);
}


/* Блок с загруженными картинок */
.news-add__attachments {
    width: 100%;
    flex-wrap: wrap;
}

.news-add__attachments .flex-col {
    width: calc(20% - 9.6px);
    gap: 0px;
}

.news-add__attachments .fullscreen-image img {
    width: 100%;
    height: 100px;
    object-fit: cover;
    border-radius: var(--border-radius);
}

.news-add__attachments button {
    width: 100%;
}