:root {
    --button-profile-img: 40px;
}

.button-profile {
    width: 100%;
    height: var(--button-height-big);
    display: flex;
    justify-content: flex-start;
}

.button-profile img {
    width: var(--button-profile-img);
    height: var(--button-profile-img);
    border-radius: 50%;
    
    background-color: var(--bg-primary);
    object-fit: cover;
}

.button-profile__text {
    /* 40px картинка и отступы с боков */
    width: calc(100% - var(--button-profile-img) - var(--button-gap) - var(--button-padding));
    display: flex;
    flex-direction: column;
}

.button-profile__text p, .button-profile__text small {
    text-align: left;
}

/* Превью кнопки */
.button-profile_preview .button-profile__text p,
.button-profile_preview .button-profile__text small {
    width: fit-content;
    color: transparent;
    background-color: var(--bg-primary);
}

.button-profile_noPadding {
    padding-right: 0;
    padding-left: 0;
}