/* Блок с прокруткой отзывов */
.reviews-wrapper {
    overflow: hidden;
    border-radius: var(--border-radius);
}

.reviews-container {
    display: flex;
    position: relative;
    top: 0;
    left: 0;
}

.review {
    background-color: var(--bg-primary);
}

.review__content {
    align-items: center;
    padding: var(--gap-small);
}

/* Фотография */
.review__photo {
    width: 100px;
}

.review__photo img {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    
    background-color: var(--bg-primary);
    object-fit: cover;
}

.review__name {
    font-size: var(--h2);
}

/* Звездочки */
.review__stars {
    gap: 4px;
}

.review__stars img {
    width: var(--button-img-big);
}

/* Текст отзыва */
.review__text {
    width: 80%;
    text-align: center;
}


/* Слайдер картинок */
.reviews-control {
    justify-content: center;
    align-items: center;
    user-select: none;
}

/* Нумерация картинки */
.reviews-control span {
    min-width: 20px;
    display: inline-block;
    text-align: center;
}