/* Скрываем текст в кнопке с картинкой */
@media screen and (max-width: 900px) {
    /* Секция с блоком */
    .vk-messages-section {}
    
    /* Секция отступ в 12px */
    .vk-messages-section.vk-messages-section_min-height {
        height: calc(100% - 31px);
    }
}

/* Мобильное сжатие */
@media screen and (max-width: 400px) {
    /* Информация о загрузке */
    .vk-messages__search-info {
        flex-direction: column;
        gap: 0;
    }

    /* Скрываем разделяющую полосу */
    .vk-messages__search-info span:nth-of-type(2) {
        display: none;
    }

    /* Делаем время сверху */
    .vk-message__info-name {
        display: flex;
        flex-direction: column-reverse;
    }

    /* Время */
    .vk-message__info-name span {
        margin-left: 0px;
        /* margin-left: auto; */
    }

    /* Делаем аву ниже */
    .vk-message__user-image {
        margin-top: var(--gap-small);
    }
}