.country-edit > button {
    margin: 0 auto;
}

/* Текст превью */
.country-edit__preview-text {
    text-align: center;
}

/* Картинка с превью */
.country-edit__preview {
    width: fit-content;
    margin: 0 auto;
}

.country-edit__preview img {
    width: 100%;
    height: 100px;
    object-fit: contain;
    border-radius: var(--border-radius);
}