body[bg] {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

body[bg=bg1] {
    background-image: url("../../assets/settings/bg1.png");
}

body[bg=bg2] {
    background-image: url("../../assets/settings/bg2.png");
}

body[bg=bg3] {
    background-image: url("../../assets/settings/bg3.png");
}

body[bg=bg4] {
    background-image: url("../../assets/settings/bg4.png");
}

body[bg=bgSnow] {
    background-image: url("../../assets/settings/bgSnow.png");
}