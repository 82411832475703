/* Блок с выбором удачи */
.dice__luck {
    align-items: center;
}

.dice__luck div {
    transition: 0s all;
}

/* Обвертка range инпута */
.dice__luck-wrapper {
    flex: 1;
    width: 100%;
    height: var(--button-height);
    display: flex;
    justify-content: center;
    align-items: center;
}


/* Таймер */
.dice__timer {
    display: none;
}

.dice__timer.show-timer {
    display: block;
}



.dice__timer-span {
    display: inline-block;
    width: 22px;
    text-align: center;
}