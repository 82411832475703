/* Контент в 100% */
@media screen and (max-width: 900px) {
    /* Фотография */
    .dossier__photo {
        width: 100px;
    }

    .dossier__photo img {
        width: 100px;
        height: 100px;
    }
}