/* Бог мне судья body[theme=hedgehog34]*/
body[theme=hedgehog34],
body[theme=hedgehog34] section,
body[theme=hedgehog34] .textarea-block,
body[theme=hedgehog34] .modal {
    background-color: #aae5a4;
}


/* Черный цвет текста */
body[theme=hedgehog34] * {
    color: #000000;
}

body[theme=hedgehog34] small {
    font-weight: 400;
    color: #0a0;
}


/* Перебитие стилей для инпута */
body[theme=hedgehog34] .custom-input input[type=text],
body[theme=hedgehog34] .custom-input textarea {
    background-color: #fff;
    color: #000000;
}

body[theme=hedgehog34] .custom-input input[type=text] ~ label,
body[theme=hedgehog34] .custom-input textarea ~ label {
    background-color: unset;
    color: #000000;
}

body[theme=hedgehog34] .custom-input input[type=text]:focus:not(:read-only) ~ label,
body[theme=hedgehog34] .custom-input input[type=text]:valid:not(:read-only) ~ label,
body[theme=hedgehog34] .custom-input input[type=text]:read-only ~ label,
body[theme=hedgehog34] .custom-input textarea:focus:not(:read-only) ~ label,
body[theme=hedgehog34] .custom-input textarea:valid:not(:read-only) ~ label,
body[theme=hedgehog34] .custom-input textarea:read-only ~ label {
    translate: 0px -22px;
    color: #000000;
    background-color: transparent;
}

/* Желтый фон инпута при активации */
body[theme=hedgehog34] .custom-input input[type=text]:focus:not(:read-only),
body[theme=hedgehog34] .custom-input textarea:focus:not(:read-only) {
    background-color: #ffc;
}


/* Перебитие стилей кнопки */
body[theme=hedgehog34] button {
    background-color: rgb(180, 239, 173) !important;
    color: #000000;
}

/* Наведение на пк */
@media (hover: hover) {
    body[theme=hedgehog34] button:hover,
    body[theme=hedgehog34] button:focus-visible,
    body[theme=hedgehog34] nav ul li a:hover,
    body[theme=hedgehog34] nav ul li a:focus-visible {
        background-color: rgb(156, 245, 146) !important;
    }
}

/* Клик на телефоне */
@media (hover: none) {
    body[theme=hedgehog34] button:active,
    body[theme=hedgehog34] nav ul li a:active {
        background-color: rgb(156, 245, 146) !important;
    }
}

body[theme=hedgehog34] button:disabled {
    background-color: rgb(180, 239, 173) !important;
}

body[theme=hedgehog34] button:disabled p {
    color: #00000099;
}

/* Меняем белые иконки на черные */
body[theme=hedgehog34] nav ul li a img,
body[theme=hedgehog34] nav ul li a::after,
body[theme=hedgehog34] .button-image:not(.no-filter) img,
body[theme=hedgehog34] .custom-input_img img,
body[theme=hedgehog34] .image-gray {
    filter: invert(1);
}


/* Маркер прокрутки */
body[theme=hedgehog34] *::-webkit-scrollbar-thumb {
    background-color: #fff;
}

/* Контент в 100% */
@media screen and (max-width: 900px) {
    body[theme=hedgehog34] aside {
        background-color: #93c393;
    }

    body[theme=hedgehog34] .nav-wrapper {
        background-color: #aae5a4;
    }
}